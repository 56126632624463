



















































import { Component, Vue } from 'vue-property-decorator';
import { H3Badge, H3DatetimePicker } from '@h3/thinking-ui';
import { WorkPlatformService } from '@WorkPlatform/services';

@Component({
  name: 'mWorkPlatFormNotices',
  components: {
    H3Badge,
    H3DatetimePicker,
    List: () => import('./list.vue'),
    searchBar: () => import('../search-bar.vue'),
  },
})
export default class mWorkPlatFormNotices extends Vue {
  tabs: any[] = [
    { name: '待办', value: '1' },
    { name: '待阅', value: '2' },
    { name: '已办', value: '3' },
    { name: '已阅', value: '4' },
  ];

  currentTodoCate: number = 0;

  // 待办消息数量
  todoTotal: number = 0;

  // 待阅消息数量
  waitRead: number = 0;

  searchTxt: any = '';

  timer: any = null;

  startTimeShow: boolean = false;

  startTime: any = '';

  endTimeShow: boolean = false;

  endTime: any = '';

  totalUnreadList = 0;

  created () {
    this.getUnreadList();
  }

  getTodoTotal (val) {
    this.todoTotal = val;
  }

  getWaitRead (val) {
    this.waitRead = val;
  }

  /**
   * 切换消息tab
   */
  todoCateClick (param: number, todoType: string) {
    if (param === this.currentTodoCate) {
      return;
    }
    this.searchTxt = '';
    this.startTime = '';
    this.endTime = '';
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.currentTodoCate = param;
    this.$nextTick(() => {
      (this.$refs.refList as any).checkTodoType(todoType);
      (this.$refs.refList as any).nextPage('', 'checkTab');
    });
  }

  getUnreadList () {
    WorkPlatformService.getTodoList({
      todoType: 2,
      page: 2,
      size: 20,
    }).then((res: any) => {
      this.totalUnreadList = res.total || 0;
    }).catch(() => {
      this.totalUnreadList = 0;
    });
  }

  /**
   * input输入 搜索
   */
  async searchChange () {
    clearTimeout(this.timer);
    const wait: number = 800;
    this.timer = setTimeout(async () => {
      await (this.$refs.refList as any).resetParams();
      (this.$refs.refList as any).nextPage('');
    }, wait);
  }

  /**
   * 选择开始时间
   */
  startTimeCheck (value) {
    this.startTime = `${value} 00:00:00`;
    this.endTimeShow = true;
  }

  /**
   * 选择结束时间
   */
  endTimeCheck (value) {
    this.endTime = `${value} 23:59:59`;
    this.$nextTick(async () => {
      await (this.$refs.refList as any).resetParams();
      (this.$refs.refList as any).nextPage('');
    });
  }

  clear () {
    this.searchTxt = '';
    this.searchChange();
  }
}
